import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBFkIRDtHw3vFFP0fBXvhSWOzIZKy6V3s0",
  authDomain: "webgames-98624.firebaseapp.com",
  projectId: "webgames-98624",
  storageBucket: "webgames-98624.appspot.com",
  messagingSenderId: "732880878272",
  appId: "1:732880878272:web:2dedc9109b962864484b25"
};

const appIn = initializeApp(firebaseConfig);
const db = getFirestore(appIn);
export default db;