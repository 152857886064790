import GameManager from './Components/MineSweeper/orgs/GameManager';
import './App.css';
import db from "./firebase";

function App() {
  return (
   <GameManager db = {db}/>
  );
}

export default App;
