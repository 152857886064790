import React from 'react';
import './RankTable.css';

const RankTable = (props) => {
    const getScore = (size, bombs, time) => {
        const cell = size.split('x')[0];
        return (cell * cell * bombs) /((time + 1)/3);
    }
    const generateTable = () => {
        let rankedGames = [];
        props.data.forEach(game => {
            game.score = Math.ceil(getScore(game.size, game.bombs, game.time));
            rankedGames.push(game);
        });
        return (rankedGames.sort((a, b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0)).slice(0, 5));
    }
    return (
        <table className='rank'>
            <caption className='rank caption'>Top 5 Scores</caption>
            <tbody className='rank'>
                <tr>
                    <th>Name</th>
                    <th>Field</th>
                    <th>Bombs</th>
                    <th>Time</th>
                    <th>Score</th>
                    <th>Date</th>
                </tr>
                {
                    generateTable().map((game) => {
                        return (<tr key={props.data.indexOf(game)}>
                            <td className='cell'>{game.nickname}</td>
                            <td className='cell'>{game.size}</td>
                            <td className='cell'>{game.bombs}</td>
                            <td className='cell'>{game.time}</td>
                            <td className='cell'>{game.score}</td>
                            <td className='cell'>{game.date}</td>
                        </tr>)

                    })
                }
            </tbody>
        </table>

    );
}

export default RankTable;